<template>
  <div class="statisticsHome flex-column-between">
    <header class="flex-align-between head" v-if="true">
      <div class="avatar flex-align">
        <div class="avatarImgb">
          <img class="avatarImg" :src="avatar1" v-if="succeed" @click="goUser" />
          <img class="avatarImg" src="@/assets/user/user.png" v-else @click="goUser" />
        </div>
        <div class="avatarname flex-column-between">
          <p id="tn">
            {{ uesrName }}
          </p>
          <p>上课时长：{{ time(teacherTime) }}</p>
          <p>上课字数：{{ teachercount }} 个</p>
        </div>
        <div class="type flex-column-center">
          <img src="@/assets/live/1.png" />
          <span>首页</span>
        </div>
      </div>
      <div class="back flex-align-between">
        <img :src="require('@/assets/task/return.png')" @click="goback" />
        <span @click="goback">返回</span>
      </div>
    </header>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "",
    components: {},
    data() {
      return {
        avatar1: "",
        succeed: false,
        teacherTime: 0,
        teachercount: 0,
        uesrName: "",
      };
    },
    methods: {
      information() {
        this.uesrName = sessionStorage.getItem("teacherName");
        let img = sessionStorage.getItem("avatar").replace(/\"/g, "");
        this.avatar1 = "https://yunapi.cnuzi.com/uploads/" + img;
        this.errorimg(this.avatar1);
      },
      //防止闪图
      errorimg(src) {
        let that = this;
        let img = new Image();
        img.src = src;
        img.onload = function () {
          that.succeed = true;
        };
        img.onerror = function () {
          that.succeed = false;
        };
      },
      async getTeacherUploadInfo() {
        this.$Api.Home.getTeacherUploadInfo().then((res) => {
          this.teacherTime = res.data.totalLength;
          this.teachercount = res.data.totalUpload;
        });
      },
      time(time) {
        var h = parseInt(
          ((time * 1000) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var m = parseInt(((time * 1000) % (1000 * 60 * 60)) / (1000 * 60));
        var s = parseInt(((time * 1000) % (1000 * 60)) / 1000);
        return h + "时" + m + "分" + s + "秒";
      },
      goUser() {
        this.$router.push("/profile");
      },
      goback() {
        this.$router.go(-1);
      },
    },
    mounted() {
      this.information();
      this.getTeacherUploadInfo();
    },
  };
</script>
<style lang="less" scoped>
  @import "./less/statisticsHome.less";
</style>